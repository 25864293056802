

// import { ICategory } from "app/interfaces/categories";
import { IRestaurant } from "app/interfaces/restaurants";
import { useGetRestaurantsMutation } from "app/services/restaurants";
import { show } from "features/windows/slice";
import { useRestaurants } from "hooks/useRestaurants";
// import header from "pages/layouts/header";
import { Button } from "primereact/button";
import { DataView } from 'primereact/dataview';
import { Divider } from "primereact/divider";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import StarRatings from "react-star-ratings";
import { isHTTPS } from "utils/regexp";

interface IProps {
  setElementId: (id: string) => void;
}

export const ViewRestaurants: React.FC<IProps> = (props: IProps) => {
  const { setElementId } = props;
  const dispatch = useDispatch();
  const { restaurants, filter } = useRestaurants()
  
  const [ getRestaurants ] = useGetRestaurantsMutation()
  // const [ actionRestaurant ] = useUpdateRestaurantActionMutation()
  // const [ deactionRestaurant ] = useUpdateRestaurantDeactiveMutation()

  useEffect(()=> { getRestaurants(filter) }, [filter]) // eslint-disable-line react-hooks/exhaustive-deps

  // const handleDeactivate = (id: string) => deactionRestaurant({restaurantId: id}).then( () =>  getRestaurants(filter));
  const handleViewing = (id: string) => {
    setElementId(id)
    dispatch(show({type: 'restaurants', name: 'edit'}))
  };

  const renderListItem = (restaurant: IRestaurant) => {
    const image = `${isHTTPS(restaurant.logo) ? restaurant.logo : '/api/admin/files/' + restaurant.logo}`
    return (
      <div className="col-12">
        <div className="col-12 p-3 flex flex-column md:flex-row align-items-center restaurants-list-item">
          <img src={image} onError={(e: any) => e.target.src= process.env.PUBLIC_URL + '/images/default_empty.png'}  alt={restaurant.name} />
          <div className="restaurants-list-detail">
            <StarRatings
              rating={+restaurant.rating}
              starRatedColor="#efb445"
              starEmptyColor="#e0e0e0"
              starDimension="20px"
              starSpacing="1px"
            />
            <div className="restaurants-name">{restaurant.name}</div>
            <Divider className="mt-1 mb-1 md:none"/>
            <span style={{fontSize: '12px'}}> <strong>{restaurant.city?.name}</strong>. <i>{restaurant.address}</i></span>
          </div>
          <div className="restaurants-list-action">
            <Button icon="pi pi-pencil" label="Редактировать" onClick={() => handleViewing(restaurant.id)}></Button>
          </div>
        </div>
      </div>
    );
}
        
  return (
    <DataView 
      value={restaurants}
      itemTemplate={renderListItem} 
    />
  );
}